export default {
  props: {
    formKey: String,
    block: Object,
    blockIndex: Number,
  },
  computed: {
    title() {
      if(!this.block.title) return null
      if(typeof this.block.title != 'function') {
        return this.$t(this.block.title)
      }
      return this.$t(this.block.title(this.formInfo))
    },
    help() {
      if(!this.block.help) return null
      if(typeof this.block.help != 'function') {
        return this.$t(this.block.help)
      }
      return this.$t(this.block.help(this.formInfo))
    },
    disabled() {
      if(this.block.disabled === true) return true
      if(typeof this.block.disabled == 'function') {
        return this.block.disabled(this.formInfo)
      }
      return false
    },
    readonly() {
      if(this.block.readonly === true) return true
      if(typeof this.block.readonly == 'function') {
        return this.block.readonly(this.formInfo)
      }
      return false
    },
    blockData() {
      return this.block.data
    },
    formInfo() {
      return this.$store.getters[`form/${this.formKey}/info`]
    },
    formConfig() {
      return this.$store.getters[`form/${this.formKey}/config`]
    },
    col() {
      return {
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
        ...this.block.grid,
      }
    },
  },
}