<template>
  <v-col
    eagle-form="block"
    cols="12"
    :xl="col.xl" :lg="col.lg" :md="col.md" :sm="col.sm" :xs="col.xs"
  >
    <v-lazy>
      <v-card>
        <v-card-title v-if="title">
          {{ title }}
        </v-card-title>

        <!--表單區塊help-->
        <v-card-subtitle v-if="help" class="primary--text">
          {{ help }}
        </v-card-subtitle>

        <v-divider></v-divider>

        <!--表單資料-->
        <component
          eagle-block-content
          :is="blockComponent"
          :block="block"
          :blockIndex="blockIndex"
          :blockReadonly="readonly"
          :blockDisabled="disabled"
          :blockData="blockData"
          :formKey="formKey"
        ></component>
      </v-card>
    </v-lazy>

  </v-col>
</template>

<script lang="babel" type="text/babel">
import formBlockMixin from '@/components/form/mixins/formBlockMixin.js'
export default {
  mixins: [formBlockMixin],
  computed: {
    blockComponent() {
      // 自訂表單區塊元件
      if(this.block.component) {
        return this.block.component
      }

      return 'formBlockContent'
    },
  },
  components: {
    formBlockContent: () => import('@/components/form/formBlockContent.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>